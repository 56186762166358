import { css } from '@emotion/react'

export const ceomessage = css`
        .title {
            font-size: 28px;
        }
        h2 {
            span {
                color: #CC050B;
            }
        }
        @media screen and (min-width: 1199px) {
            .title {
                font-size: 40px;
            }
        }
    `