import { ceomessage } from '../../styles/ceomessage'

export const CEOmessage = ({ isIRPage }: { isIRPage: boolean }) => {
  return (
    <div css={ceomessage}>
      <section className="mb-20 bg-white pc:py-12 pc:px-8 pc:mb-16">
        <img
          className="hidden pb-24 pc:block"
          src={
            isIRPage
              ? `/common/img/ceo03PC.jpg?v=2024-04-30`
              : `/common/img/ceo01PC.jpg?v=2024-04-30Zz`
          }
          alt="CRGホールディングス 代表取締役社長 小田 康浩"
        />
        <img
          className="pb-20 pc:hidden"
          src={
            isIRPage
              ? `/common/img/ceo03SP.jpg?v=2024-04-30`
              : `/common/img/ceo01SP.jpg?v=2024-04-30`
          }
          alt="CRGホールディングス 代表取締役社長 小田 康浩"
        />
        <h2 className="mb-8 font-bold leading-normal text-center pc:mb-12 title">
          働くことの<span>幸</span>せを
          <br />
          より<span>多</span>くの人に届けたい
        </h2>
        <p className="mb-20 text-center text-md pc:mb-28">
          それがCRGグループの変わらぬ願いです
        </p>
        <p className="mb-2 text-center texte-md">
          CRGホールディングス 代表取締役社長
        </p>
        <div className="w-[152px] mx-auto">
          <img src="/common/img/sign.png?v=2024-04-30" alt="小田 康浩" />
        </div>
      </section>
      <section className="mb-20 bg-white pc:py-12 pc:px-8 pc:mb-16">
        <h2 className="mb-4 text-lg font-bold leading-normal pc:text-xxl">
          <span>人</span>と<span>チカラ</span>の融合
        </h2>
        <p className="leading-relaxed text-md">
          CRGグループでは、「人のチカラ」の融合を事業方針として掲げ、人材派遣紹介事業を主力とするヒューマンリソースサービス、採用・事務代行や製造請負等のアウトソーシングサービス、業務のデジタル化・自動化をサポートする
          ITソリューションサービス等を展開する総合人材サービス企業です。近年では、これまで以上に期待されているシニア、女性、グローバル人材といった潜在労働力の活用促進や、障がいをお持ちの方の雇用機会の創出や処遇の確保・改善にも注力しております。また、専門的なサービス領域の拡充として通訳・翻訳サービスに加え、グローバル展開を検討されている企業のサポートも新規事業として開始しております。
        </p>
      </section>
      <section className="mb-20 bg-white pc:py-12 pc:px-8 pc:mb-16">
        <h2 className="mb-4 text-lg font-bold leading-normal pc:text-xxl">
          「成長を<span>愉</span>しもう。」
        </h2>
        <p className="leading-relaxed text-md">
          CRGグループでは、社会変化に対応し持続的に事業を拡大し続けるために、「人」を大切にするという考えのもと「成長を愉しもう。」という企業理念を掲げています。グループの従業員、仕事に携わる登録スタッフ、より良い労働力を求めるクライアント企業、株主も含めて、共に手を携えて、より大きな組織、より理想とするカタチを作り上げていくことを愉しみたいという思いが込められています。
        </p>
      </section>
      <section className="bg-white pc:py-12 pc:px-8">
        <h2 className="mb-4 text-lg font-bold leading-normal pc:text-xxl">
          <span>働きがい</span>も<span>経済成長</span>も
        </h2>
        <p className="leading-relaxed text-md">
          CRGグループが属する人材サービス業界におきましては、生産年齢人口の減少に伴う構造的な人手不足は継続しているものの、働き方改革の急伸や人的資本経営の拡大に伴うBPOやDX関連サービスの拡充など、人材サービス業界を取り巻く環境は大きな変革期にあります。このような市場環境の中、CRGグループとしても管理体制の一層の強化、及び積極的なM&Aを含めた成長戦略の推進と収益率の改善を図り、「働きがいも経済成長も」というSDGsにも寄与しながら企業価値の向上に努めてまいります。
        </p>
      </section>
    </div>
  )
}
