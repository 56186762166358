import React from 'react'
import { LayoutDefault } from '../../components/organisms/Layout/LayoutDefault'
import { sideNavDataCompany } from '../../components/data/sideNavData'
import { SEO } from '../../components/data/SEO'
import { CEOmessage } from '../../components/organisms/CEOmessage/CEOmessage'
import { ENGLISH_TITLE } from '../../components/data/English'

const Page = () => {
  return (
    <LayoutDefault
      heading="代表メッセージ"
      headingEnglish={ENGLISH_TITLE.CEOMessage}
      BreadcrumbData={[
        { title: '企業情報', url: '/company' },
        { title: '代表メッセージ', url: '' },
      ]}
      sideNavHeading="企業情報"
      sideNavList={sideNavDataCompany}
    >
      <CEOmessage isIRPage={false} />
    </LayoutDefault>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEO title="代表メッセージ" url="/company/message" />
