import React, { ReactNode } from 'react'
import { SideNav } from '../SideNav/SideNav'
import { MvChildPage } from '../Mv/MvChildPage'
import { layoutList } from '../../styles/layout'
import { LayoutRoot } from './LayoutRoot'

interface Props {
  children: ReactNode
  heading: string
  headingEnglish: string
  BreadcrumbData: Array<{ title: string; url: string }>
  sideNavHeading: string
  sideNavList: Array<{ text: string; url: string }>
}

/**
 * 下層ページのレイアウト
 * @param {ReactNode} children
 * @param {string} heading
 * @param {string} headingEnglish
 * @param {Array} BreadcrumbData
 * @param {string} sideNavHeading
 * @param {Array} sideNavList
 */
/** */
export const LayoutDefault = ({
  children,
  heading,
  BreadcrumbData,
  headingEnglish,
  sideNavHeading,
  sideNavList,
}: Props) => {
  return (
    <LayoutRoot>
      <MvChildPage
        heading={heading}
        headingEnglish={headingEnglish}
        BreadcrumbData={BreadcrumbData}
      />
      <div
        css={layoutList.layoutInner}
        className="flex justify-between flex-col pc:flex-row pc:items-start"
      >
        <div className="pc:w-[932px] pb-14 relative">{children}</div>
        <div className="pc:w-[212px] w-full">
          <SideNav heading={sideNavHeading} linkList={sideNavList} />
        </div>
      </div>
    </LayoutRoot>
  )
}
